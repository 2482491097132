<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      devicelist: [],
      goodsinfo: {},
      selectdevice: [],
      hdinfolist: [],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.goods.binddevice");
    this.items = [
      {
        text: this.$t("menuitems.goods.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.goods.binddevice"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('glob
    this.getdevicelist();
    this.getgoodsdetail();
  },
  methods: {
    getgoodsdetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getgoods",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.goodsinfo = response.data.data;
          that.coverimg = response.data.data.coverimg;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdevicelist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "deviceslist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.devicelist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changedevice(value) {
      console.log(value);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <p>
              <el-alert
                :title="$t('goods.lists.bindtip')"
                type="warning"
                :closable="false"
              ></el-alert>
            </p>

            <div class="row">
              <div class="col-6">
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>{{ $t("goods.lists.title") }}</span>
                    <router-link to="/goods/list">
                      <el-button
                        style="float: right; padding: 3px 0"
                        type="text"
                        >{{ $t("goods.lists.return") }}</el-button
                      >
                    </router-link>
                  </div>
                </el-card>
              </div>
              <div class="col-6">
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>{{ $t("goods.lists.devices") }}</span>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p>
                        <el-select
                          v-model="selectdevice"
                          multiple
                          :placeholder="$t('global.text.select')"
                          @change="changedevice($event)"
                        >
                          <el-option
                            v-for="item in devicelist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                            <span
                              style="
                                float: right;
                                color: #8492a6;
                                font-size: 13px;
                              "
                              >{{ item.ED_id }}</span
                            >
                          </el-option>
                        </el-select>
                      </p>
                      <p>
                        <el-form
                          ref="hdinfolist"
                          label-width="80px"
                          label-position="left"
                          status-icon
                        >
                          <div class="addFrom">
                            <div
                              class="addFrom_box"
                              v-for="(item, index) in hdinfolist"
                              :key="index"
                            >
                              <!-- 嵌套检验 -->
                              
                            </div>
                          </div>
                        </el-form>
                      </p>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>